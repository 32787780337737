import { Injectable } from '@angular/core';
import { User } from '@sentry/angular';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({ providedIn: 'root' })
export class GoogleLoginService {
  private clientId = '1025042132072-v6rqp3ae1bukj9ni62u0spcuajp7q0gc.apps.googleusercontent.com';
  private authUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();

  constructor() {}

  loginWithGoogle(redirectUri: string): void {
    const params = {
      client_id: this.clientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: 'openid',
      state: this.generateRandomString(32),
      prompt: 'consent',
    };

    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    window.location.href = `${this.authUrl}?${queryString}`;
  }

  private generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
}
