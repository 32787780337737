import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../button/button.component';
import { UserService } from '../../../../services/authentication/user.service';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { ModalService } from '../../../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-profile-confirmation-prompt-modal',
  templateUrl: './profile-confirmation-prompt-modal.page.html',
  styleUrls: ['./profile-confirmation-prompt-modal.page.scss'],
  imports: [ButtonComponent, TranslateModule],
})
export class ProfileConfirmationPromptModalPage {
  type?: 'edition' | 'creation';

  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {}

  get title(): string {
    return this.type === 'edition' ? 'PWA_editProfile_exit_popup_header' : 'PWA_createProfile_exit_popup_header';
  }

  get description(): string {
    return this.type === 'edition' ? 'PWA_editProfile_exit_popup_subtitle' : 'PWA_createProfile_exit_popup_subtitle';
  }

  get cancelTitle(): string {
    return this.type === 'edition' ? 'PWA_editProfile_exit_CTA_cancel' : 'PWA_createProfile_exit_CTA_cancel';
  }

  get confirmTitle(): string {
    return this.type === 'edition' ? 'PWA_editProfile_exit_CTA_link_yesExit' : 'PWA_createProfile_exit_CTA_link_yesExit';
  }

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.LeaveProfileChangeModal });
  }

  cancel(): void {
    void this.modalService.dismiss({ cancel: true });
  }

  confirm(): void {
    void this.modalService.dismiss({ cancel: false });
  }
}
